import { useEffect, useState } from 'react'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'

import Dial from '../components/ui/Dial'
import EnumIndicator from '../components/ui/EnumIndicator'

import { useParams } from 'react-router-dom'

import { useApi } from '../hooks/useApi'
import { useAuth } from '../hooks/useAuth'


export const DevicePage = () => {
  const [ device, setDevice ] = useState({})

  // Mocked UI level values:
  const [ fanSpeed, setFanSpeed ] = useState(4560)
  const [ temperature, setTemperature ] = useState(90.5)
  const [ decibles, setDecibles ] = useState(45)

  useEffect(() => {
    console.log('using effect for ui variables')

    const interval = setInterval(() => {
      setFanSpeed(4560 + Math.floor((Math.random() * 200 - 100)))
      setTemperature(90.5 + (Math.floor(Math.random() * 10)/10 - 0.5))
      setDecibles(45 + Math.floor((Math.random() * 10 - 5)))
    }, 500)

    return () => clearInterval(interval)
  }, [fanSpeed, temperature, decibles])

  const { user } = useAuth()
  const { device: deviceApi } = useApi()

  const { id } = useParams()

  useEffect(() => {
    const getDevice = async () => {
      const device = await deviceApi.get(id)
      setDevice(device)
    }
    getDevice()
  }, [])

  return (
    <Container maxWidth="lg" sx={{ mb: 4}}>
      <Typography
        variant="h4"
        sx={{ pt: 4, mb: 4 }}
        color="primary"
      >
        Device {device.id}
      </Typography>
      <Grid container spacing={3}>
        <Grid
          item
          xs={12}
          md={6}
          lg={4}
          align = "center"
          justify = "center"
          alignItems = "center"
        >
          <Paper
            sx={{
              p: 4,
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: 'rgba(18, 18, 18, 0.2)',
            }}
            elevation={10}
          >
            {/* <Dial
              title="Fan Speed"
              value={device.remoteInfo?.fanSpeed}
              min={0}
              max={6000}
              units="RPM"
            >
            </Dial> */}
            <Dial
              title="Fan Speed"
              value={fanSpeed}
              min={0}
              max={6000}
              units="RPM"
            >
            </Dial>            
          </Paper>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          lg={4}
          align = "center"
          justify = "center"
          alignItems = "center"
        >
          <Paper
            sx={{
              p: 4,
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: 'rgba(18, 18, 18, 0.2)',
            }}
            elevation={10}
          >
            <EnumIndicator
              title="Heater Status"
              value={device.remoteInfo?.heatStatus.toUpperCase()}
              options={["OFF", "ON"]}
            >
            </EnumIndicator>
          </Paper>
          <Paper
            sx={{
              p: 4,
              mt: 2,
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: 'rgba(18, 18, 18, 0.2)',
            }}
            elevation={10}
          >
            <EnumIndicator
              title="Heater Mode"
              value={device.remoteInfo?.mode.toUpperCase()}
              options={["TRAINING", "MINING", "RENDERING"]}
            >
            </EnumIndicator>
          </Paper>  
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          lg={4}
          align = "center"
          justify = "center"
          alignItems = "center"
        >
          <Paper
            sx={{
              p: 4,
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: 'rgba(18, 18, 18, 0.2)',
            }}
            elevation={10}
          >
            {/* <Dial
              title="Temperature"
              value={device.remoteInfo?.tempCelcius}
              min={0}
              max={150}
              units="°C"
            >
            </Dial> */}
            <Dial
              title="Temperature"
              value={temperature}
              min={70}
              max={100}
              units="°C"
            >
            </Dial>
          </Paper>          
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          lg={4}
          align = "center"
          justify = "center"
          alignItems = "center"
        >
          <Paper
            sx={{
              p: 4,
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: 'rgba(18, 18, 18, 0.2)',
            }}
            elevation={10}
          >
            {/* <Dial
              title="Decibles"
              value={device.remoteInfo?.decibles}
              min={0}
              max={200}
              units="dB"
            >
            </Dial> */}
            <Dial
              title="Decibles"
              value={decibles}
              min={0}
              max={200}
              units="dB"
            >
            </Dial>
          </Paper>
        </Grid>
      </Grid>

    </Container>
  )
}