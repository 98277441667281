import { createContext, useContext, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLocalStorage } from './useLocalStorage'

import { getApiClient } from '../apiClient'

const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null)
  const [token, setToken] = useLocalStorage('token', null)
  const navigate = useNavigate()

  const login = async ({ email, password }, next) => {
    const apiClient = getApiClient()
    const token = await apiClient.login({ email, password })
    setToken(token)
    navigate(next || '/')
  }

  const getUser = async () => {
    try {
      const apiClient = getApiClient(token)
      const user = await apiClient.getUser()
      setUser(user)
    }
    catch(error) {
      console.error('Error getting user', error)
      logout()
    }
  }

  const register = async ({ email, password, firstName, lastName }) => {
    const apiClient = getApiClient()
    const token = await apiClient.register({ email, password, firstName, lastName })

    setToken(token)
    navigate('/')
  }

  const logout = () => {
    setToken(null)
    setUser(null)
    navigate('/login')
  }

  const value = useMemo(() => {
    return {
      user,
      token,
      login,
      getUser,
      logout,
      register,
    }
  }, [token, user])

  return (
    <AuthContext.Provider value={ value }>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => useContext(AuthContext)