import { useEffect } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Chip from '@mui/material/Chip'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import TagIcon from '@mui/icons-material/Tag';

import { useNavigate, Link } from 'react-router-dom'

import { useApi } from '../hooks/useApi'


const Devices = () => {
  const { devices } = useApi()
  const navigate = useNavigate()

  useEffect(() => {
    devices.get()
  }, [])

  if (devices.loading) {
    return (
      <Container>
        <Typography variant="body1">Loading devices...</Typography>
        <CircularProgress color="secondary" />
      </Container>
    )
  }
  else if (devices.error) {
    return (
      <Container>
        <Typography variant="body1">An error occurred while loading devices.</Typography>
        <Typography variant="body1">{devices.error.message}</Typography>
      </Container>
    )
  }
  else if (!devices.current?.length) {
    // Show no devices and activation button.
    return (
      <Container>
        <Typography variant="body1">Looks like you don't have any active heaters.</Typography>
        <Link to="/activate">
          <Button
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
          >
            Activate Now!
          </Button>
        </Link>
      </Container>
    )
  }
  // Else there should be devices, so show them.
  else {
    return (
      <Container>
        <Typography
          variant="h6"
          color="primary"
          sx={{ mb: 1 }}
        >
          YOUR DEVICES
        </Typography>
        <Grid container spacing={2}>
          {devices.current.map(device => (
            <Grid
              item
              key={device.id}
              sx={{
                cursor: 'pointer',
              }}
              onClick={() => navigate(`/devices/${device.id}`)}
            >
              <Paper elevation={15} sx={{p: 4}}>
                <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
                  <FingerprintIcon color="primary" sx={{ mr: 1 }}/>
                  <Typography variant="body1">{device.name}</Typography>
                </Box>
                <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
                  <TagIcon color="primary" sx={{ mr: 1 }}/>
                  <Typography variant="body1">{device.serialNumber}</Typography>
                </Box>                
                <Typography variant="body1">{device.description}</Typography>
                <Chip
                  label={
                    device.deviceStatus === 'connecting' ? 'CONNECTED' : 'DISCONNECTED'
                  }
                  color={
                    device.deviceStatus === 'connecting' ? 'success' : 'error'
                  }
                  variant='filled'
                />
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Container> 
    )
  }
}

export default Devices