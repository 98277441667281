import { useEffect } from 'react';
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';

import { useAuth } from '../../hooks/useAuth'

const UserInfo = () => {
  const { user } = useAuth()
  return user && (
    <>
      <Grid
        container
        sx={{ pb: 1 }}
      >
        <Grid
          item
          sx={{ px: 1 }}
        >
          <PersonIcon color="secondary" />
        </Grid>
        <Grid
          item
          xs={10}
        >
          <Typography
            variant="button"
            color="primary"
          >
            {user.firstName} {user.lastName}
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        sx={{ pb: 1 }}
      >
        <Grid
          item
          sx={{ px: 1 }}
        >
          <EmailIcon color="secondary" />
        </Grid>
        <Grid
          item
          xs={10}
        >
          <Typography
            variant="button"
            color="primary"
          >
            {user.email}
          </Typography>
        </Grid>
      </Grid>
    </>
  )  
}


const User = ({ enableNext }) => {

  const { user } = useAuth()

  useEffect(() => {
    enableNext()
  }, [])

  if (!user) {
    return (
      <Typography variant="h6">
        Uh oh! Looks like you're not logged in. Please log in to continue.
      </Typography>
    )
  }

  return (
    <>
      <Typography variant="body1" sx={{ mb: 2 }}>
        Here's the user information that we'll use to activate the device.
      </Typography>
      <UserInfo />
    </>
  )

}

export { User, UserInfo, }