import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'

const AccountInfo = () => {

  return (
    <Container>
      <Typography variant="h6" color="primary">ACCOUNT INFO</Typography>
      <Typography variant="h3">$0.00</Typography>
    </Container> 
  )

}

export default AccountInfo