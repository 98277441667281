import { Navigate, useOutlet, useLocation } from 'react-router-dom'
import Navbar from './Navbar'
import Box from '@mui/material/Box'
import { useAuth } from '../hooks/useAuth'

export const ProtectedLayout = () => {
  const { token, user, getUser } = useAuth()
  const outlet = useOutlet()
  const location = useLocation()

  if (!token) {
    let next = ''
    if (location) {
      next = `?next=${location.pathname}`
    }
    return <Navigate to={`/login`} />
  }

  if (!user) {
    getUser()
  }

  return (
    <div>
      <Navbar />
      <Box sx={{
        height: '100vh',
        backgroundImage:'url(/data-cable.png)',
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}>
        { outlet }
      </Box>
    </div>
  )
}