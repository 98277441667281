import Box from "@mui/material/Box"
import CircularProgress from "@mui/material/CircularProgress"
import Typography from "@mui/material/Typography"

import { useTheme } from "@mui/material/styles";

const Dial = ({
  title,
  units,
  min,
  max,
  value,
}) => {

  const theme = useTheme()
  const valuePercent = ((value - min) / (max - min)) * 100

  let color = "#00FF00"
  if (valuePercent < 25) {
    color = theme.palette.success.light
  }
  else if (valuePercent < 50) {
    color = theme.palette.success.dark
  }
  else if (valuePercent < 75) {
    color = theme.palette.warning.light
  }
  else {
    color = theme.palette.error.light
  }

  return (
    <>
      <Typography
        variant="overline"
        color="primary"
      >
        {title} ({units})
      </Typography>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          mt: 2,
        }}
      >
        <Box
          sx={{
            position: 'relative',
            display: 'inline-flex'
          }}
        >
          <CircularProgress
            variant="determinate"
            value={valuePercent}
            size={200}
            thickness={7}
            sx={{
              color: {color},
              // color: "error.light"
              transition: "color 0.5s",
            }}
          />
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography
              variant="caption"
              component="div"
              color="primary"
            >
              {value}
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  )

}

export default Dial