import { useState, useEffect } from 'react'

export const useLocalStorage = (key, defaultValue) => {
  const initialValue = () => {
    try {
      const value = window.localStorage.getItem(key)
      return value ? JSON.parse(value) : defaultValue
    } catch (error) {
      return defaultValue
    }
  }

  const [storedValue, setStoredValue] = useState(initialValue)

  useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(storedValue))
  }, [key, storedValue])

  return [storedValue, setStoredValue]
}
