import { DatasetRounded } from '@mui/icons-material'
import config from '../config'
import ActivateDevice from '../components/activation/ActivateDevice'

export const login = async ({ email, password }) => {
  const response = await fetch(
    `${config.apiBasePath}/login`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        password,
      }),
    },
  )

  if (!response.ok) {
    throw new Error('Invalid email or password')
  }

  const data = await response.json()
  return data.token
}

export const register = async ({ email, password, firstName, lastName }) => {
  const response = await fetch(
    `${config.apiBasePath}/register`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        password,
        firstName,
        lastName,
      }),
    },
  )

  if (!response.ok) {
    throw new Error('Registration failed')
  }

  const data = await response.json()
  return data.token
}

const getUser = async (token) => {
  const response = await fetch(
    `${config.apiBasePath}/user`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    },
  )

  if (!response.ok) {
    throw new Error('Failed to fetch user')
  }

  // deserialize user:
  const { data } = await response.json()

  return {
    id: data.id,
    firstName: data.attributes.firstName,
    lastName: data.attributes.lastName,
    email: data.attributes.email,
  }
}

const getDevices = async (token) => {
  const response = await fetch(
    `${config.apiBasePath}/devices`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    },
  )

  if (!response.ok) {
    throw new Error('Failed to fetch devices')
  }

  const { data } = await response.json()

  return data.map((device) => ({
    id: device.id,
    ...device.attributes,
  }))

}

const getDevice = async (token, deviceId) => {
  const response = await fetch(
    `${config.apiBasePath}/device-info/${deviceId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    },
  )

  if (!response.ok) {
    throw new Error('Failed to fetch device')
  }

  const { data } = await response.json()

  return {
    id: data.id,
    ...data.attributes,
  }

}

const checkActivationCode = async (token, activationCode) => {
  const response = await fetch(
    `${config.apiBasePath}/device-activation/${activationCode}`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    },
  )

  if (!response.ok) {
    return new Promise(resolve => setTimeout(() => resolve(false), 1000))
    return false
  }

  // deserialize
  const { data } = await response.json()
  if (data.attributes?.status === 'ready') {
    return new Promise(resolve => setTimeout(() => resolve(true), 1000))
    return true
  }

}

const activateDevice = async (token, { activationCode, deviceName, }) => {
  const response = await fetch(
    `${config.apiBasePath}/activate-device`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        deviceName,
        activationCode,
      }),
    },
  )

  if (!response.ok) {
    throw new Error('Failed to activate device')
  }

  // deserialize device
  const { data } = await response.json()

  return {
    id: data.id,
    ...data.attributes,
  }
}

export const getApiClient = (token) => {
  // const basePath = config.apiBasePath

  return {
    login,
    register,
    getUser: () => getUser(token),
    getDevices: () => getDevices(token),
    getDevice: (deviceId) => getDevice(token, deviceId),
    checkActivationCode: (activationCode) => checkActivationCode(token, activationCode),
    activateDevice: (activationCode, deviceName) => activateDevice(token, { activationCode, deviceName }),
  }
}