import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

const DeviceInfo = ({ setDeviceName, enableNext }) => {
  const handleNameChange = (event) => {
    console.log(event.target.value)
    setDeviceName(event.target.value)

    if (event.target.value.length > 0) {
      enableNext()
    }
  }

  return (
    <>
      <Box
        sx={{
          my: 8,
          mx: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant="h6" color="primary">Provide the device info</Typography>
        <TextField
          margin="normal"
          required
          fullWidth
          id="name"
          label="Device Name"
          name="name"
          autoFocus
          onChange={handleNameChange}
        />
      </Box>
    </>
    
  )

}

export default DeviceInfo