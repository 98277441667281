import { useState } from 'react'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import FireplaceIcon from '@mui/icons-material/Fireplace'
import FormControlLabel from '@mui/material/FormControlLabel'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import { UserInfo } from './User'

const Confirm = ({ deviceName, setNextEnabled }) => {
  const [confirmChecked, setConfirmChecked] = useState(false)

  const handleChange = (event) => {
    setConfirmChecked(event.target.checked)
    setNextEnabled(event.target.checked)

  }

  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Typography
          variant="body1"
          color="primary"
          sx={{ mb: 4 }}
        >
          CONFIRM THE INFORMATION IS CORRECT
        </Typography>
      </Box>
      <UserInfo />
      <Grid
        container
        sx={{ pb: 1 }}
      >
        <Grid
          item
          sx={{ px: 1 }}
        >
          <FireplaceIcon color="secondary" />
        </Grid>
        <Grid
          item
          xs={10}
        >
          <Typography
            color="primary"
          >
            { deviceName }
          </Typography>
        </Grid>      
      </Grid>

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={confirmChecked}
              onChange={handleChange}
              value="confirm"
              color="primary"
            />
          }
          label={
            <Typography variant="body1" color="primary">LOOKS GOOD!</Typography>
          }
        />
      </Box>
    </>
  )

}

export default Confirm