import Container from '@mui/material/Container'

import { useParams } from 'react-router-dom'

import ActivateDevice from '../components/activation/ActivateDevice'

export const ActivatePage = () => {

  const { code } = useParams()

  return (
    <Container>
      <ActivateDevice activationCode={ code }/>
    </Container>
  )

}