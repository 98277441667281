import { Navigate, useOutlet } from 'react-router-dom'
import { useAuth } from '../hooks/useAuth'

export const LoginLayout = () => {
  const outlet = useOutlet()
  const { user } = useAuth()

  if (user) {
    return <Navigate to="/" />
  }

  return (
    <div>
      { outlet }
    </div>
  )
}