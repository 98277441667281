import { useState } from 'react'
import { useAuth } from './useAuth'
import { getApiClient } from '../apiClient'

export const useApi = () => {
  const { token } = useAuth()
  const [ devices, setDevices ] = useState([])
  const [ devicesLoading, setDevicesLoading ] = useState(false)
  const [ devicesError, setDevicesError ] = useState(null)

  const [ deviceLoading, setDeviceLoading ] = useState(false)
  const [ deviceError, setDeviceError ] = useState(null)

  const [ activationCodeVerificationError, setActivationCodeVerificationError ] = useState(null)
  const [ activationError, setActivationError ] = useState(null)

  const client = getApiClient(token)

  const getDevices = async () => {
    setDevicesLoading(true)
    try {
      const devices = await client.getDevices()
      setDevices(devices)
      setDevicesLoading(false)
    }
    catch (error) {
      setDevicesError(error)
      setDevicesLoading(false)
    }
  }

  const getDevice = async (deviceId) => {
    setDeviceLoading(true)
    try {
      const device = await client.getDevice(deviceId)
      setDeviceLoading(false)
      return device
    }
    catch (error) {
      setDeviceError(error)
      setDeviceLoading(false)
    }
  }

  const checkActivationCode = async (activationCode) => {
    try {
      return await client.checkActivationCode(activationCode)
    }
    catch (error) {
      setActivationCodeVerificationError(error)}
  }

  const activateDevice = async (activationCode, deviceName) => {
    try {
      return await client.activateDevice(activationCode, deviceName)
    }
    catch (error) {
      setActivationError(error)
    }
  }

  return {
    devices: {
      get: getDevices,
      current: devices,
      loading: devicesLoading,
      error: devicesError,
    },
    device: {
      get: getDevice,
      loading: deviceLoading,
      error: deviceError,
    },
    activation: {
      check: checkActivationCode,
      activate: activateDevice,
      checkError: activationCodeVerificationError,
      activationError,
    }
  }
}