import { useState } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'

import { useNavigate, useParams } from 'react-router-dom'

import { User } from './User'
import Code from './Code'
import DeviceInfo from './DeviceInfo'
import Confirm from './Confirm'
import FadeIn from '../ui/FadeIn'

import { useApi } from '../../hooks/useApi'

const ActivateDevice = () => {
  const navigate = useNavigate()
  const { code } = useParams()
  const [step, setStep] = useState(0)
  const [nextEnabled, setNextEnabled] = useState(false)
  const [activating, setActivating] = useState(false)

  const [activationCode, setActivationCode] = useState('')
  const [deviceName, setDeviceName] = useState('')

  const { activation } = useApi()

  const steps = [
    'user',
    'code',
    'device',
    'confirm',
  ]

  const handleNext = async () => {
    if (step < steps.length - 1) {
      setStep(step + 1)
    }
    else {
      setActivating(true)
      await activation.activate(activationCode, deviceName)
      setActivating(false)
      navigate('/')
    }
    console.log('disabling button')
    setNextEnabled(false)
  }

  const enableNext = () => {
    setNextEnabled(true)
  }

  const testClick = (clicked) => {
    console.log(clicked)
  }

  return (
    <>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="h4"
          color="primary"
          sx={{ m: 4 }}
        >
          ACTIVATE YOUR DEVICE
        </Typography>
      </Grid>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: '50vh' }}
      >
        <Grid
          item
          container
          xs={9}
          direction="column"
          alignItems="center"
          justifyContent="center"        
        >
          <FadeIn
            show={step === 0}         
          >
            <div
              onClick={() => testClick('user')}

            >
              <User
                enableNext={() => setNextEnabled(true)}
              />
            </div>
          </FadeIn>
          <FadeIn
            show={step === 1}                 
          >
            <div
              onClick={() => testClick('code')}           

            >
              <Code
                numChars={6}
                setActivationCode={setActivationCode}
                enableNext={() => setNextEnabled(true)}
                initialCode={code}
              />
            </div>
          </FadeIn>
          <FadeIn
            show={step === 2}
          >
            <div
              onClick={() => testClick('device')}

            >
              <DeviceInfo
                setDeviceName={setDeviceName}
                setNextEnabled={setNextEnabled}
                enableNext={() => setNextEnabled(true)}
              />          
            </div>
          </FadeIn>
          <FadeIn
            show={step === 3}
          >
            <div
              onClick={() => testClick('confirm')}
            >
              <Confirm
                deviceName={deviceName}
                enableNext={enableNext}
                setNextEnabled={setNextEnabled}
              />          
            </div>
          </FadeIn>
        </Grid>
        <Grid
          item
          container
          xs={3}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Button
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
            onClick={handleNext}
            disabled={!nextEnabled}
          >
            Next
          </Button>
          {
            activating && <CircularProgress />
          }
          
        </Grid>
      </Grid>
    </>
  )

}

export default ActivateDevice