import Box from "@mui/material/Box"
import Chip from "@mui/material/Chip"
import Typography from "@mui/material/Typography"

const EnumIndicator = ({
  title,
  options,
  value,
}) => {

  return (
    <>
      <Typography
        variant="overline"
        color="primary"
      >
        {title}
      </Typography>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          mt: 2,
        }}
      >
        <Box
          sx={{ display: 'inline-flex'}}
        >
          {
            options?.map((option) => (
              <Chip
                key={option}
                label={option}
                color='primary'
                variant={option === value ? 'filled' : 'outlined'}
                sx={{
                  mx: 1,
                }}
              >
              </Chip>
            ))
          }
        </Box>
      </Box>
    </>
  )
}

export default EnumIndicator