import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  defer,
} from 'react-router-dom'

import { AuthLayout } from './components/AuthLayout'
import { LoginLayout } from './components/LoginLayout'
import { ProtectedLayout } from './components/ProtectedLayout'

// Protected pages
import { HomePage } from './pages/Home'
import { DevicePage } from './pages/Device'
import { ActivatePage } from './pages/Activate'

// Public pages
import { LoginPage } from './pages/Login'
import { RegistrationPage } from './pages/Register'

import config from './config'

const getUserData = async () => {

  const lsToken = localStorage.getItem('token')
  const token = JSON.parse(lsToken)

  if (!token) {
    return null
  }

  const response = await fetch(
    `${config.apiBasePath}/user`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    },
  )

  if (!response.ok) {
    localStorage.removeItem('token')
    return null
  }

  return response.json()
}

export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route
        element={ <AuthLayout /> }
        loader={ 
          () => defer({ userPromise: getUserData() })
        }
      >
        <Route element={ <LoginLayout /> }>
          <Route path="/login" element={ <LoginPage /> } />
          <Route path="/register" element={ <RegistrationPage /> } />"
        </Route>
        <Route element={<ProtectedLayout />}>
          <Route path="/" element={ <HomePage /> } />
          <Route path="/devices/:id" element={ <DevicePage /> } />
          <Route path="/activate/:code?" element={ <ActivatePage /> } />
        </Route>
      </Route>
    </>
  ),
)