import { useState, useRef, useEffect } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'

import { useApi } from '../../hooks/useApi'
import { CircularProgress } from '@mui/material'

const Code = ({ numChars=6, setActivationCode, enableNext, initialCode='' }) => {

  // Validate initial code
  let initialCodeArray
  if (initialCode.length === numChars) {
    initialCodeArray = initialCode.split('')
  }

  const { activation } = useApi()
  const [code, setCode] = useState(initialCodeArray ?? new Array(numChars).fill(''))
  const [error, setError] = useState(null)
  const [checkingCode, setCheckingCode] = useState(false)
  const [codeValid, setCodeValid] = useState(false)

  const codeBoxRef = useRef([])

  const handleChange = (value, index) => {
    let newCode = [...code]
    newCode[index] = value.toUpperCase()
    setCode(newCode)

    if (value && index < numChars - 1) {
      codeBoxRef.current[index + 1].focus()
    }
  }

  const handleSpecialInput = (e, index) => {
    if (e.key === "Backspace" && !e.target.value && index > 0) {
      codeBoxRef.current[index - 1].focus()
    }
    if (e.key === "Enter" && e.target.value && index < numChars - 1) {
      codeBoxRef.current[index + 1].focus()
    }
  }

  useEffect(() => {
    setCodeValid(false)
    setError(null)

    if (code.filter(Boolean).length < numChars) {
      return
    }

    const codeStr = code.join('')

    const checkCode = async () => {
      setCheckingCode(true)
      const status = await activation.check(codeStr)
      if (status === true) {
        setError(null)
        setCodeValid(true)
        setActivationCode(codeStr)
        enableNext()
      }
      else {
        setActivationCode('')
        setError('Invalid activation code, please try again')
      }
      setCheckingCode(false)
    }

    checkCode()
  }, [code])

  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="h6" color="primary">Enter your code</Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {
          code.map((char, index) => (
            <TextField
              key={index}
              inputProps={{
                maxLength: 1,
                onKeyUp: (e) => handleSpecialInput(e, index),
                style: {
                  fontSize: '4rem',
                  color: '#FF6900',
                }
              }}
              value={char}
              onChange={(e) => handleChange(e.target.value, index)}
              inputRef={el => codeBoxRef.current[index] = el}
              color='primary'
              sx={{
                width: '5rem',
                textAlign: 'center',
                margin: '1rem',
              }}
            />
          ))
        }
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
      {
        codeValid &&
          <Typography
            variant="body1"
            color="primary"
            sx={{ ml: 1 }}
          >
            Activation code is valid
          </Typography>
      }
      {
        error && <Typography variant="body1" color="error">{error}</Typography>
      }
      {
        checkingCode &&
        <>
          <CircularProgress color="primary" size={20}/>
          <Typography
            variant="body1"
            color="primary"
            sx={{ ml: 1 }}
          >
            Checking activation code
          </Typography>
        </>
      }
      </Box>  
    </>    
  )

}

export default Code