import { Suspense } from 'react'
import { useLoaderData, useOutlet, Await } from 'react-router-dom'
import LinearProgress from '@mui/material/LinearProgress'
import Alert from '@mui/material/Alert'
import { AuthProvider } from '../hooks/useAuth'

export const AuthLayout = () => {
  const outlet = useOutlet()

  const { userPromise } = useLoaderData()

  return (
    <Suspense fallback={<LinearProgress />}>
      <Await
        resolve={userPromise}
        errorElement={<Alert severity="error">An error occurred while loading the user.</Alert>}
        children={( user ) => (
          <AuthProvider userData={user}>
            {outlet}
          </AuthProvider>
        )}
      />
    </Suspense>
  )
}