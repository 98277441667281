import { useState, useEffect } from "react"

const FadeIn = ({ children, show }) => {

  const [display, setDisplay] = useState('none')
  const [opacity, setOpacity] = useState(0)

  useEffect(() => {
    setOpacity(0)
    if (show) {
      // Set display first so that transitions occur.
      setDisplay('block')
    }
    else {
      setDisplay('none')
    }
  }, [show])

  useEffect(() => {
    if (display === 'block') {
      setTimeout(() => {
        setOpacity(1)
      }, 100)
    }
  }, [display])

  return (
    <div
      style={{
        transition: 'opacity 1s linear',
        opacity: opacity,
        // visibility: show ? 'visible' : 'hidden',
        display,
      }}
    >
      { children }
    </div>
  )

}

export default FadeIn