import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'

import Devices from '../components/Devices'
import AccountInfo from '../components/AccountInfo'

import { useAuth } from '../hooks/useAuth'

export const HomePage = () => {
  const { user } = useAuth()

  return (
    <Container maxWidth="lg" sx={{ mb: 4}}>
      <Typography variant="h4" sx={{ pt: 4, mb: 4 }} color="primary">
        Welcome {user?.firstName}!
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8} lg={9}>
          <Paper
            sx={{
              p: 4,
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: 'rgba(18, 18, 18, 0.2)',
            }}
            elevation={10}
          >
            <Devices />
          </Paper>
        </Grid>
        <Grid item xs={12} md={8} lg={9}>
          <Paper
            sx={{
              p: 4,
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: 'rgba(18, 18, 18, 0.2)',
            }}
            elevation={10}
          >
            <AccountInfo />
          </Paper>
        </Grid>        
      </Grid>

      

      
    </Container>
  )
}