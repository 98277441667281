import { createTheme } from '@mui/material/styles'

export const theme = createTheme({
    palette: {
        mode: 'dark',
        // primary: {
        //   main: '#6091f1',
        // },
        // secondary: {
        //   main: '#494fb3',
        // },
        // primary: {
        //   main: '#00a474',
        // },
        // secondary: {
        //   main: '#ff6900',
        // },
        primary: {
          main: '#00C9D9',
          contrastText: '#ffffff',
        },
        secondary: {
          main: '#FF001E',
        },
      },
})